body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.quiz-separator {
  margin-bottom: 40px;
} 

.quiz-answer-body  {
  margin-bottom: 10px !important;
} 

.test-title {
    display: flex;  
    justify-content: left; 
    align-items: left; 
    margin-bottom: 20px
  }